@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;700&amp;display=swap');

body {
    font-family: Barlow, serif !important;
}

.h1,
.h2,
.h3,
.h4,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Barlow, serif !important;
    font-weight: 300;
    color: #3c4858;
}

.title {
    font-family: Barlow, serif;
    font-weight: 700;
}

a.navbar-brand {
    font-family: Barlow, serif !important;
    font-weight: 700;
    font-size: 1em;
    text-decoration: none;
    color: white;
    padding-bottom: .45rem;
}

.navbar {
    padding: 0 !important;
}

@media only screen and (max-width: 600px) {

    nav.navbar {
        padding: 24px 0px !important;
    }
    a.navigation {
        font-size: 14px !important;
        padding: 16px 16px !important;
        border-left: 4px solid #000;
    }
    a.navigation.active {
        border-left: 4px solid white !important;
    }
    a.navbar-brand {
        margin-left: 16px !important;
    }
    .nav-user {
        margin-left: 16px;
        margin-top: 16px;
    }
}

@media only screen and (min-width: 601px) {
    a.navigation {
        font-size: 14px;
        padding: 24px 16px !important;
        border-bottom: 4px solid #000;
    }
    a.navigation:hover {
        border-bottom: 4px solid rgba(255, 255, 255, 0.5);
    }
    a.navigation.active {
        border-bottom: 4px solid rgba(255, 255, 255, 1);
    }
    
  }

nav.bg-dark {
    background-color: black !important;
}

table.table {
    color: #3c4858;
}

.btn-outline-primary,
.btn-primary {
    transition: all 0.3s ease;
    background-color: #fff;
    color: #22ad5c;
    border-color: #22ad5c;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:disabled {
    color: #fff;
    border-color: #22ad5c !important;
    background-color: #22ad5c !important;
    box-shadow: none !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:disabled {
    color: #fff;
    border-color: #22ad5c !important;
    background-color: #22ad5c !important;
    box-shadow: none !important;
}

.btn {
    border-color: #22ad5c !important;
    color: black !important;
    line-height: 1.6;
}

.btn-danger {
    border-color: #fff !important;
    color: #fff !important;
}

.btn:hover {
    color: white !important;
}

.table td {
    padding: 0.5rem;
}

.header {
    text-align: center;
}

.backgroundWhite {
    background-color: #fff;
}

.backgroundGrey {
    background-color: #e6e6e6;
}

.margin-top {
    margin-top: 3rem;
}

.contact-support {
    background-color: rgb(183, 183, 183);
    margin: 3rem 0 3rem 0;

    box-shadow: #006eff;
}

.error {
    color: red;
}

.success {
    color: green;
}

.eventDescriptionWysiwyg {
    min-height: 120px;
}