.card-container {
    width: 300px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    animation: ease-in;
    margin-top: 2rem;
  }

  .card-container:hover{
    transform: scale(1.02);
    box-shadow:0px 0px 15px 0px rgba(0, 0, 0, 0.366);
  }
    
  .card-content {
    text-align: center;
    margin-top: 2rem;
  } 

  .image-container img{
      /*background: url(https://bromeconseil.com/wp-content/uploads/2019/03/audience.jpg); */
      overflow: hidden;
      height: auto;
      width: 100%;
  }

  h4, p{
    /*Reset to default value*/
    margin: 0;
    padding: 0;
  }

  .card-title{
    margin-top: 2rem;
  }

  .btn{
    display: flex;
    justify-content: center;
  }
  
  .btn-container {
    display: flex;
    justify-content: center;
    border-color: #fff !important;
    color: black !important;
    line-height: 1.6;
  }

  .btn-container button{
    padding: 1rem;
    /*background-color: #9c27b0;*/
    background-color: white;
    border: 1px solid #22ad5c;
    transition: 0.2s;
    margin: 0.5rem;
    border-radius: 10px;
  }

  .btn-container button:hover{
    background-color: #22ad5c;
    color: white;
  }
  
  .btn button{
    padding: 1rem;
    /*background-color: #9c27b0;*/
    background-color: white;
    border: 1px solid #22ad5c;
    transition: 0.2s;
    margin: 0.5rem;
    border-radius: 10px;
  }

  .btn button:hover{
    background-color: #22ad5c;
    color: white;
  }

  /*a{
    text-transform: uppercase;
    color: #1B9CFC;
    text-decoration: none;
    font-weight: bold;
  }*/

  .support-card {
    text-align: center;
    width: 100%;
    height: 110px;
    box-shadow: 0px 0px 15px -5px rgb(0, 0, 0);
    margin-top: 2rem;
    margin-bottom: 4rem;
    background-color: #22ad5c;
    color: white;
  }

  .mail-support{
    color: rgb(219, 219, 219);
  }