.techcontainer {

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  color: #FFF;
  padding-top: 1rem;
  width: 50vw;
}

.titleHelp {
  background-color: #22ad5c;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.7rem;
}

.question {
  font-size: larger;
  color: #fff;
}

.answere {
  color: #000;
  text-indent: 0px;
  border: 1px solid #22ad5c;
  border-radius: 0 0 1rem 1rem;
  padding: 0.5rem;
}

.sub {
  color: #000;
  padding: 1rem;
  margin-top: 2rem;
}

.phone {
  color:rgb(237, 236, 236);
}

.quickinfo {
  color:#22ad5c;
}

.accordion {
  max-width: 800px;
  margin: 1rem auto;
  color: white;
}

.accordion-title {
  display: flex;
  font-size: large;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #22ad5c;
  margin-top: 1rem;
}

.accordion-title:hover {
  background-color: #22ad5c;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: white;
  color: black;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);


}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}