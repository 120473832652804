/* Colors of the buttons*/
.button-active, .selectors button:hover, .selectors button:active, .selectors button:focus{
    color: #9c27b0;
    box-shadow: none;
}

.button-inactive{
    color: #000;
}

.button-active .selector-holder .material-icons {
    color: #9c27b0;
}
